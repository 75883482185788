import { ReactElement, useMemo } from 'react';
import {
  Leader, ThunkDispatchType, actions, OrgUserAutocompleteOption, OrganizationLeader,
  RootState, SharedLeaderGrant, StandardLeaderPermissions, CategoryLeaderPermissions, SchedulingPermission
} from '../../store';
import { trackEvent } from '../../utils/appAnalyticsUtils';
import { EVENT_TYPE } from '../../constants';
import { useMountEffect } from '../../utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import ShareLeader from './Sharing';


interface Props {
  leader: Leader | OrganizationLeader;
  isOpen: boolean;
  onDidDismiss: () => void;
  isAdminUser?: boolean;
}

export const ShareLeaderContainer = ({ leader, isOpen, onDidDismiss, isAdminUser }: Props): ReactElement => {
  const organization = useSelector((root: RootState) => root.organization);
  const orgSharedLeaderGrants = useSelector((root: RootState) => root.leaders.orgSharedLeaderGrants);
  const profileCategories = useSelector((root: RootState) => root.profileNotes.categories);
  const dispatch = useDispatch<ThunkDispatchType>();

  const leaderProfileCategories = useMemo(() => Object.fromEntries(Object.values(profileCategories).filter(
    cat => cat.leader === leader.id
  // NOTE: make profileCategories hashable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ).map((cat) => [cat.id, cat])), [leader.id, JSON.stringify(profileCategories)]);

  useMountEffect(() => {
    dispatch(actions.organization.fetchOrgUserAutocompleteOptions());
  });

  const handleShare = async (userIds: OrgUserAutocompleteOption['value'][],
    standardPermissions: StandardLeaderPermissions,
    categoryPermissions: CategoryLeaderPermissions,
    schedulingPermissions: SchedulingPermission,
    startDate: string, endDate: string | null, isAdmin: boolean,
  ) => {
    await dispatch(actions.leaders.shareLeaderProfile(
      userIds, leader.id, standardPermissions, categoryPermissions, schedulingPermissions,
      startDate, endDate, isAdmin, isAdminUser
    ));
    if (isAdminUser) {
      dispatch(actions.leaders.fetchLeaders());
    }

    trackEvent(EVENT_TYPE.SHARE_EXEC_PROFILE);
  };

  const handleRevoke = async (grantId: SharedLeaderGrant['id'], leaderId: Leader['id']) => {
    await dispatch(actions.leaders.revokeSharedLeaderProfile(grantId, leaderId, isAdminUser));
    if (isAdminUser) {
      dispatch(actions.leaders.fetchLeaders());
    }
  };

  const handleUpdateLeaders = async (leaders: Leader[]) => {
    return dispatch(actions.leaders.updateLeaders(leaders));
  };

  const sharedLeaderGrants = orgSharedLeaderGrants[leader.id] || [];

  return (
    <ShareLeader
      leader={leader}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      sharedLeaderGrants={sharedLeaderGrants}
      shareWithUserOptions={organization.userAutocompleteOptions}
      leaderProfileCategories={leaderProfileCategories}
      onShare={handleShare}
      onRevoke={handleRevoke}
      onUpdateLeaders={handleUpdateLeaders}
    />
  );
};

export default ShareLeaderContainer;
