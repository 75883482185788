import React, { ReactElement } from 'react';
import { DateTime } from 'luxon'; 
import { Typography } from '@mui/material';

interface Props {
  date: string;
  format: string;
}

export const Luxon = ({date, format}: Props): ReactElement => (
  <Typography>{DateTime.fromISO(date).toFormat(format)}</Typography>
);

export default Luxon;