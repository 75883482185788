import { ReactElement, useState, useRef, Fragment, useMemo } from 'react';
import {
  Leader, OrgUserAutocompleteOption, StandardLeaderPermissions, OrganizationLeader, SharedLeaderGrant,
  ProfileCategory, CategoryLeaderPermissions, SchedulingPermission
} from '../../store';
import { getLeaderPermission } from '../../store/templates';
import SharedLeaderItem from './SharedLeaderItem';
import { CabDatePicker, CabButton, CabAutocomplete, CabSwitch, CabCheckbox } from '@CabComponents';
import { CabModal } from '@CabComponents/CabModal';
import { 
  Box, Divider, FormControl, FormLabel, Grid, List, ListItem, Snackbar, styled, Typography 
} from '@mui/material';
import colors from '../../colors';
import { CabControlLabel } from '@CabComponents/CabControlLabel';
import LeaderEmailsModal from '../Schedule/LeaderEmailsModal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { DependentController } from '../../utils/formUtils';


interface Props {
  leader: Leader | OrganizationLeader;
  isOpen: boolean;
  onDidDismiss: () => void;
  sharedLeaderGrants: SharedLeaderGrant[];
  shareWithUserOptions: OrgUserAutocompleteOption[];
  leaderProfileCategories: { [k: string]: ProfileCategory };
  onShare: (userIds: OrgUserAutocompleteOption['value'][], 
    standardPermissions: StandardLeaderPermissions,
    categoryPermissions: CategoryLeaderPermissions,
    schedulingPermissions: SchedulingPermission,
    startDate: string, endDate: string | null, isAdmin: boolean,
  ) => Promise<void>;
  onRevoke: (grantId: SharedLeaderGrant['id'], leaderId: Leader['id']) => void;
  onUpdateLeaders: (leaders: Leader[]) => Promise<void>;
}


interface ShareGrantForm {
  collaborators: OrgUserAutocompleteOption['value'][];
  startDate: string;
  endDate: string | null;
  schedulingPermissionEdit: boolean;
  schedulingPermissionAddToMeetings: boolean;
  standardPermissions: StandardLeaderPermissions;
  customCategoryPermissions: CategoryLeaderPermissions;
  allowAllPermissions: boolean;
}

const permissionLabelMap: { [key in keyof StandardLeaderPermissions]: string } = {
  basic: 'Basic Info',
  airlines: 'Airlines',
  hotels: 'Hotels',
  personal: 'Family',
};

export const ShareLeader = ({
  leader, isOpen, onDidDismiss, sharedLeaderGrants, shareWithUserOptions, leaderProfileCategories, onShare, 
  onRevoke, onUpdateLeaders
}: Props): ReactElement => {
  const topOfViewRef = useRef<HTMLDivElement>(null);
  const primaryAssistantId = typeof(leader.primary_assistant) === 'number' ? leader.primary_assistant 
    : leader.primary_assistant?.id || -1;

  const [shareConfirmationMessage, setShareConfirmationMessage] = useState<null | string>(null);

  const defaultValues = useMemo(() => ({
    collaborators: [],
    startDate: DateTime.now().toISO(),
    endDate: null,
    schedulingPermissionEdit: false,
    schedulingPermissionAddToMeetings: true,
    standardPermissions: {
      basic: getLeaderPermission(),
      airlines: getLeaderPermission(),
      hotels: getLeaderPermission(),
      personal: getLeaderPermission(),
    },
    customCategoryPermissions: Object.values(leaderProfileCategories)
      .map(c => ({ [c.id]: getLeaderPermission() }))
      .reduce((prev, cur) => ({ ...prev, ...cur }), {}),
    allowAllPermissions: false,
  }), [leaderProfileCategories]);

  const { control, formState, setValue, handleSubmit, reset } = useForm<ShareGrantForm>({ values: defaultValues });

  const handleShareProfile: SubmitHandler<ShareGrantForm> = async (data) => {
    const shareMessage = `${leader.first_name} ${leader.last_name} has been successfully shared with 
    ${shareWithUserOptions.filter(u => data.collaborators.includes(u.value))
      .map(c => c.label.replace(/\([^()]*\)/g, '').trim())
      .join(', ')}`;

    await onShare(
      data.collaborators,
      data.standardPermissions,
      data.customCategoryPermissions,
      {
        create: data.schedulingPermissionEdit,
        view: data.schedulingPermissionEdit,
        edit: data.schedulingPermissionEdit,
        delete: data.schedulingPermissionEdit,
        add_to_meeting: data.schedulingPermissionAddToMeetings,
      },
      data.startDate,
      data.endDate || null,
      data.allowAllPermissions,
    );

    topOfViewRef.current?.scrollIntoView({block: 'end', inline: 'nearest', behavior: 'smooth'});
    setShareConfirmationMessage(shareMessage);
    reset();
    onDidDismiss();
  };

  return (
    <>
      <CabModal
        open={isOpen}
        onClose={onDidDismiss}
        title={`Share ${leader.first_name}`}
        closeIcon
        closeOnBackdropClick
        actionButtons={
          <CabButton 
            onClick={handleSubmit(handleShareProfile)} 
            disabled={!formState.isValid || formState.isLoading || formState.isSubmitting}
          >
            Share
          </CabButton>
        }
        sx={{ '& .MuiPaper-root': { width: 600 } }}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Box>
            <FormLabel>
              <Typography variant="body1">Collaborators</Typography>
            </FormLabel>

            {sharedLeaderGrants.map((grant) => {
              return (
                <SharedLeaderItem key={grant.id} grant={grant} leader={leader}
                  categories={leaderProfileCategories}
                  revoke={onRevoke}
                />
              );
            })}

            {sharedLeaderGrants.length === 0 && (
              <Typography marginTop={1} fontWeight="bold">
                None of your teammates currently have access to this profile
              </Typography>
            )}
          </Box>

          <Box>
            <Controller name="collaborators" control={control} render={({ field: { ref, ...field }}) => (
              <>
                <FormLabel>
                  <Typography variant='body1'>Share Teammate with:</Typography>
                </FormLabel>
                <CabAutocomplete<number, never>
                  onChange={(v) => Array.isArray(v) && field.onChange(v ? v : null)}
                  multiple
                  clearable
                  placeholder={field.value.length < 1 ? 'Find teammates by name or email' : undefined}
                  options={shareWithUserOptions.filter(o => o.value !== primaryAssistantId)}
                />
              </>
            )} />
            <Grid container marginTop={"16px"} padding={0}>
              <Grid item xs={5}>
                <Controller name="startDate" control={control} render={({ field: { ref, ...field }}) => (
                  <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <CabDatePicker {...field} />
                  </FormControl>
                )} />
              </Grid>
              <Grid item xs={2}/>
              <Grid item xs={5}>
                <Controller name="endDate" control={control} render={({ field: { ref, ...field }}) => (
                  <FormControl>
                    <FormLabel>End Date</FormLabel>
                    <CabDatePicker {...field} futureOnly clearable />
                  </FormControl>
                )} />
              </Grid>
              <Grid item xs={12}>
                <Controller name="allowAllPermissions" control={control} render={({ field: { ref, ...field } }) => (
                  <CabControlLabel
                    label='Allow All Permissions'
                    labelPlacement='end'
                    control={<CabSwitch {...field} />}
                    sx={{ marginTop: 1 }}
                  />
                )} />
                <Typography sx={{color: colors.black500, marginLeft: 7}}>
                  User can manage everything on behalf of this teammate
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <PermissionHeader>
              <ProfileSectionLabel>Scheduling</ProfileSectionLabel>
              <Box>
                <Box component="span" marginRight={"13px"}>Edit</Box>
              </Box>
            </PermissionHeader>

            <List>
              <PermissionRow>
                <DependentController
                  name="schedulingPermissionAddToMeetings"
                  control={control}
                  dependencies={["allowAllPermissions"]}
                  render={({ field: { ref, ...field }, deps: [allowAllPermissions] }) => (
                    <>
                      <FormLabel slot="start">
                        Allow adding this person to meetings
                      </FormLabel>
                      <CabCheckbox slot='end'
                        {...field}
                        onChange={e => {
                          field.onChange(e);
                          if (!e.target.checked) {
                            setValue('schedulingPermissionEdit', false);
                          }
                        }}
                        checked={(field.value || allowAllPermissions) as boolean}
                        disabled={allowAllPermissions as boolean}
                        sx={{ marginLeft: 3 }}
                      />
                    </>
                  )}
                />
              </PermissionRow>

              <PermissionRow>
                <DependentController
                  name="schedulingPermissionEdit"
                  control={control}
                  dependencies={["allowAllPermissions"]}
                  render={({ field: { ref, ...field }, deps: [allowAllPermissions] }) => (
                    <>
                      <FormLabel slot="start">
                        Allow viewing/editing of all meetings
                      </FormLabel>
                      <CabCheckbox slot='end'
                        {...field}
                        onChange={e => {
                          field.onChange(e);
                          if (e.target.checked) {
                            setValue('schedulingPermissionAddToMeetings', true);
                          }
                        }}
                        checked={(field.value || allowAllPermissions) as boolean}
                        disabled={allowAllPermissions as boolean}
                        sx={{ marginLeft: 3 }}
                      />
                    </>
                  )}
                />
              </PermissionRow>
              <Divider/>
            </List>

            <PermissionHeader>
              <ProfileSectionLabel>Profile</ProfileSectionLabel>
              <Box>
                <Box component="span" marginRight={"18px"}>View</Box>
                <Box component="span" marginRight={"13px"}>Edit</Box>
              </Box>
            </PermissionHeader>
            <List>
              {Object.entries(formState.defaultValues?.standardPermissions || {}).map(([category, value]) => (
                <Fragment key={category}>
                  <PermissionRow>
                    <FormLabel slot="start">
                      {permissionLabelMap[category as keyof StandardLeaderPermissions]}
                    </FormLabel>
                    <Box>
                      <DependentController
                        name={`standardPermissions.${category as keyof StandardLeaderPermissions}.view`}
                        control={control}
                        dependencies={["allowAllPermissions"]}
                        render={({ field: { ref, ...field }, deps: [allowAllPermissions] }) => (
                          <CabCheckbox slot='end'
                            {...field}
                            onChange={e => {
                              field.onChange(e);
                              if (!e.target.checked) {
                                setValue(
                                  `standardPermissions.${category as keyof StandardLeaderPermissions}.edit`, false);
                              }
                            }}
                            checked={(field.value || allowAllPermissions) as boolean}
                            disabled={allowAllPermissions as boolean}
                          />
                        )}
                      />
                      <DependentController
                        name={`standardPermissions.${category as keyof StandardLeaderPermissions}.edit`}
                        control={control}
                        dependencies={["allowAllPermissions"]}
                        render={({ field: { ref, ...field }, deps: [allowAllPermissions] }) => (
                          <CabCheckbox slot='end'
                            {...field}
                            onChange={e => {
                              field.onChange(e);
                              if (e.target.checked) {
                                setValue(
                                  `standardPermissions.${category as keyof StandardLeaderPermissions}.view`, true);
                              }
                            }}
                            checked={(field.value || allowAllPermissions) as boolean}
                            disabled={allowAllPermissions as boolean}
                            sx={{ marginLeft: 3 }}
                          />
                        )}
                      />
                    </Box>
                  </PermissionRow>
                  <Divider/>
                </Fragment>
              ))}

              {Object.keys(leaderProfileCategories).map(categoryId =>
                <Fragment key={categoryId}>
                  <PermissionRow>
                    <FormLabel slot="start">
                      {leaderProfileCategories[categoryId].title}
                    </FormLabel>
                    <Box>
                      <DependentController
                        name={`customCategoryPermissions.${categoryId}.view`}
                        control={control}
                        dependencies={["allowAllPermissions"]}
                        render={({ field: { ref, ...field }, deps: [allowAllPermissions] }) => (
                          <CabCheckbox slot='end'
                            {...field}
                            onChange={e => {
                              field.onChange(e);
                              if (!e.target.checked) {
                                setValue(`customCategoryPermissions.${categoryId}.edit`, false);
                              }
                            }}

                            checked={(field.value || allowAllPermissions) as boolean}
                            disabled={allowAllPermissions as boolean}
                          />
                        )}
                      />
                      <DependentController
                        name={`customCategoryPermissions.${categoryId}.edit`}
                        control={control}
                        dependencies={["allowAllPermissions"]}
                        render={({ field: { ref, ...field }, deps: [allowAllPermissions] }) => (
                          <CabCheckbox slot='end'
                            {...field}
                            onChange={e => {
                              field.onChange(e);
                              if (e.target.checked) {
                                setValue(`customCategoryPermissions.${categoryId}.view`, true);
                              }
                            }}
                            checked={(field.value || allowAllPermissions) as boolean}
                            disabled={allowAllPermissions as boolean}
                            sx={{ marginLeft: 3 }}
                          />
                        )}
                      />
                    </Box>
                  </PermissionRow>
                  <Divider/>
                </Fragment>
              )}
            </List>
          </Box>
        </Box>
      </CabModal>

      <Snackbar
        open={!!shareConfirmationMessage}
        onClose={() => setShareConfirmationMessage(null)}
        autoHideDuration={8000}
        message={shareConfirmationMessage}
        sx={{ marginTop: '96px' }}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      />

      {isOpen && !leader.email && "is_shared" in leader && (
        <LeaderEmailsModal
          isOpen={isOpen && !leader.email}
          leaders={[leader]}
          onEmailsSubmitted={onUpdateLeaders}
          onCancel={onDidDismiss}
        />
      )}
    </>
  );
};

const ProfileSectionLabel = styled("span", { label: "ProfileSectionLabel" })({
  fontWeight: "bold",
  marginLeft: "15px",
});

const PermissionHeader = styled(Box, { label: "PermissionHeader" })({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "5px",
});

const PermissionRow = styled(ListItem, { label: "PermissionRow" })({
  display: "flex",
  justifyContent: "space-between"
});

export default ShareLeader;
